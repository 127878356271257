import Select from "@/components/Select";
import SelectDestination from "@/public/svg/common/enquire-wizard/destination.svg";
import Person from "@/public/svg/common/enquire-wizard/number-persons.svg";
import SelectDuration from "@/public/svg/common/enquire-wizard/select-duration.svg";
import TravelMonth from "@/public/svg/common/enquire-wizard/travel-month.svg";
import Input from "@/components/Input";
import { cn } from "@/utils/twMerge";

interface Step1Props {
    step: number;
    state: {
        destination: string;
        travelMonth: string;
        duration: string;
        persons: number;
        name: string;
        email: string;
        whatsapp: string;
        enqId: string;
        pageUrl: string;
    };
    setState: (state: any) => void;
    showDestinationDropdown: boolean;
}

const months = new Array(12).fill(0).map((_, index) => {
    return new Date(new Date().getFullYear(), index).toLocaleString("default", {
        month: "long",
    });
});

months.splice(0, 0, "Select Month");

const durations = [
    "Select Duration",
    "3 Nights / 4 Days",
    "4 Nights / 5 Days",
    "5 Nights / 6 Days",
    "6 Nights / 7 Days",
    "7 Nights / 8 Days",
    "8 Nights / 9 Days",
    "9 Nights / 10 Days",
];

const persons = [
    "Select Persons",
    "solo",
    "2 pax",
    "3 pax",
    "4 pax",
    "5 pax",
    "6 pax",
    "7 pax",
    "8 pax",
    "9 pax",
    "10+ pax",
];

const Step1 = (props: Step1Props) => {
    const { step, state, setState, showDestinationDropdown } = props;
    return (
        <div
            className="flex-col gap-4"
            style={{ display: step == 1 ? "flex" : "none" }}
        >
            <Select
                icon={SelectDestination}
                placeholder="Select Destination"
                name="Destination"
                options={[
                    "Select Destination",
                    "Thailand",
                    "Bali",
                    "Maldives",
                    "Vietnam",
                    "Dubai",
                    "Sri Lanka",
                    "Singapore",
                    "Malaysia",
                    "Hong Kong",
                    "Andaman",
                ]}
                className={cn(!showDestinationDropdown && "hidden")}
                onChange={(e) => {
                    setState({ ...state, destination: e.target.value });
                }}
                value={state.destination}
            />
            <Select
                icon={TravelMonth}
                name="Travel Month"
                options={months}
                placeholder="Select Travel Month"
                onChange={(e) => {
                    setState({ ...state, travelMonth: e.target.value });
                }}
                value={state.travelMonth}
            />
            <Select
                icon={SelectDuration}
                name="Duration"
                options={durations}
                placeholder="Select Duration"
                onChange={(e) => {
                    setState({ ...state, duration: e.target.value });
                }}
                value={state.duration}
            />
            <Select
                icon={Person}
                name="Number of Persons"
                options={persons}
                placeholder="Select Number of Persons"
                onChange={(e) => {
                    setState({
                        ...state,
                        persons: persons.indexOf(e.target.value),
                    });
                }}
                value={persons[state.persons]}
            />
            <input
                type="hidden"
                name="Enquiry Id"
                onChange={(e) => { }}
                value={state.enqId}
            />
            <input
                type="hidden"
                name="Page Url"
                onChange={(e) => { }}
                value={state.pageUrl}
            />
        </div>
    );
};

export default Step1;
