import Image from "next/image";
import { InputHTMLAttributes } from "react";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    icon: string;
}

const Input = (props: InputProps) => {
    const { name, icon } = props;
    return (
        <div className="form-input flex flex-row gap-2">
            <figure className="shrink-0">
                <Image loading="lazy"
                    src={icon}
                    alt={name}
                    className="avatar"
                    width={44}
                    height={44}
                />
            </figure>
            <div className="flex flex-col ">
                <label htmlFor={name} className="text-sm font-semibold">
                    {name}
                </label>
                <input autoComplete="off" {...props} />
            </div>
        </div>
    );
};

export default Input;
