import Input from "@/components/Input";
import Email from "@/public/svg/mail.svg";
import Name from "@/public/svg/name.svg";
import Whatsapp from "@/public/svg/whatsapp.svg";
import Language from "@/public/svg/lang2.svg"
import Select from "./Select";
import PhInput from "@/components/PhInput/PhInput"
import { useEffect } from "react";

interface Step2Props {
    step: number;
    state: {
        destination: string;
        travelMonth: string;
        duration: string;
        persons: string | number;
        name: string;
        email: string;
        whatsapp: string;
        enqId: string;
        pageUrl: string;
        language: string;
    };
    setState: (state: any) => void;
}


const Step2 = (props: Step2Props) => {
    const { step, state, setState } = props;



    return (
        <div
            className="flex flex-col gap-4"
            style={{ display: step == 2 ? "flex" : "none" }}
        >
            <Input
                icon={Name}
                id="Name"
                name="Name"
                placeholder="Your Name"
                onChange={(e) => {
                    setState({ ...state, name: e.target.value });
                }}
                value={state.name}
            />
            <Input
                icon={Email}
                id="Email"
                name="Email"
                placeholder="youremail@gmail.com"
                onChange={(e) => {
                    setState({ ...state, email: e.target.value });
                }}
                value={state.email}
            />

            <PhInput
                icon={Whatsapp}
                name="Whatsapp"
                placeholder="99999 99999"
                type="number"
                onChange={(phone: any, meta: any, e:any) => {
                    setState({ ...state, whatsapp: phone , countryCode:meta.country.iso2});
                }}
                value={state.whatsapp}
            />

            <Select
                icon={Language}
                placeholder={"Language"}
                name="Select Language"
                options={[
                    "Language",
                    "English",
                    "Tamil",
                    "Hindi",
                    "Telugu",
                    "Kanada",
                    "Malayalam"
                ]}
                onChange={(e) => {
                    setState({ ...state, language: e.target.value });
                }}
                value={state.language}
            />
        </div>
    );
};

export default Step2;
